<template>
    <div class="d-flex flex-column overflow-hidden h-100">
        <vue-custom-scrollbar
            class="app-content-padding flex-grow-1 overflow-hidden h-100"
        >
            <ts-page-title
                :title="$t('employee.pageTitle')"
                :breadcrumb="[
                    { text: $t('home'), href: '/' },
                    {
                        text: $t('employee.pageTitle'),
                        href: '/admin/employees'
                    },
                    {
                        text: $t('create'),
                        active: true
                    }
                ]"
            />
            <div class="demo-spin-article">
                <div class="row">
                    <div class="col-md-3 tw-mb-5">
                        <profile
                            v-model="model"
                            :validate="errors"
                            class="tw-h-full"
                            ref="profile"
                        />
                    </div>
                    <div class="col-md-9 tw-mb-5">
                        <ts-panel>
                            <ts-panel-wrapper>
                                <form
                                    class="form-horizontal form-horizontal-text-right"
                                >
                                    <details-template
                                        v-model="model"
                                        :validation="errors"
                                    />
                                    <nssf-template
                                        v-model="model"
                                        :validation="errors"
                                    />
                                    <payroll-template
                                        v-model="model"
                                        :validation="errors"
                                    />
                                </form>
                            </ts-panel-wrapper>
                        </ts-panel>
                    </div>
                </div>
            </div>
            <Spin size="large" fix v-if="loading"> </Spin>
        </vue-custom-scrollbar>
        <div
            id="footer"
            class="app-footer m-0 tw-flex tw-justify-end tw-space-x-2 tw-sh"
            style="background-color: #e9ecef"
        >
            <ts-button
                @click.prevent="$router.push({ name: 'employee' })"
                class="btn-gray"
            >
                {{ $t('cancel') }}</ts-button
            >
            <ts-button
                :waiting="waiting"
                @click.prevent="onUpdate"
                color="primary"
            >
                {{ $t('update') }}</ts-button
            >
        </div>
    </div>
</template>

<script>
import AppOptions from '@/config/AppOptions.vue'
import Profile from './components/profile.vue'
import NssfTemplate from './components/nssf'
import DetailsTemplate from './components/detail'
import PayrollTemplate from './components/payroll'
import { mapActions, mapState } from 'vuex'
import { Errors } from 'form-backend-validation'
import moment from 'moment'
import { isEmpty } from 'lodash'

export default {
    name: 'employeeEdit',
    components: {
        Profile,
        NssfTemplate,
        DetailsTemplate,
        PayrollTemplate
    },
    data () {
        return {
            loading: false,
            waiting: false,
            waiting_new: false,
            errors: new Errors(),
            model: {
                employee_name_kh: null,
                employee_name_en: null,
                sex: null,
                phone: null,
                card_id: null,
                email: null,
                address: null,
                photo: null,
                position_id: null,
                hire_date: moment().format('DD-MM-YYYY'),
                probation_end_date: moment().add(3, 'M').format('DD-MM-YYYY'),
                status_id: 1,
                status_effective_date: null,
                shift_id: null,
                pc_date_id: null,
                payment_type_id: null,
                is_tax_responsed_by_company: false,
                is_spouse_dependant: false,
                num_child_dependant: 0,
                nssf_number: null,
                ssn_no: null,
                ssn_issued_date: null,
                ssn_expiry_date: null,
                ssn_issued_at: null,
                nationality_id: null,
                date_of_birth: null,
                emp_type_id: null,
                status_reason_id: null,
                fp_serial_card: null,
                num_of_payroll: null,
                staff_type_id: 1
            }
        }
    },
    computed: {
        ...mapState('humanResource/employee', ['edit_data'])
    },
    created () {
        AppOptions.appContentFullHeight = true
        AppOptions.appContentClass = 'p-0'
    },
    methods: {
        ...mapActions('humanResource/employee', [
            'getPosition',
            'getStatus',
            'getStatusReason',
            'getShift',
            'getPcDate',
            'getPaymentType',
            'getNationality',
            'getStaffType',
            'find'
        ]),
        async fetchResource () {
            this.loading = true
            await this.getPosition()
            await this.getStatus()
            await this.getStatusReason()
            await this.getShift()
            await this.getPcDate()
            await this.getPaymentType()
            await this.getNationality()
            await this.getStaffType()
            await this.find(this.$route.params.id).catch(error => {
                this.notice({ type: 'error', text: error.message })
            })
            this.setEditData()
            this.$refs.profile.setEditData()
            this.loading = false
        },
        photoUpload () {
            if (
                this.model.photo instanceof File ||
                this.model.photo instanceof Blob
            ) {
                let formData = new FormData()
                formData.append('photo', this.model.photo)
                return this.$store.dispatch(
                    'humanResource/employee/photoUpload',
                    formData
                )
            }

            return this.model.photo
        },
        async onUpdate () {
            this.errors = new Errors()
            this.waiting = true
            let photo_path = await this.photoUpload()
            this.$store
                .dispatch('humanResource/employee/update', {
                    id: this.model.employee_id,
                    data: Object.assign({}, this.model, {
                        photo: photo_path
                    })
                })
                .then(response => {
                    this.notice({
                        type: response.message_id == 0 ? 'success' : 'warning',
                        text: response.message
                    })
                    this.$router.push({ name: 'employee' })
                })
                .catch(error => {
                    this.errors = new Errors(error.errors)
                    this.notice({ type: 'error', text: error.message })
                })
                .finally(() => {
                    this.waiting = false
                })
        },
        async setEditData () {
            if (!isEmpty(this.edit_data)) {
                this.model.employee_id = this.edit_data.employee_id
                this.model.employee_name_kh = this.edit_data.employee_name_kh
                this.model.employee_name_en = this.edit_data.employee_name_en
                this.model.sex = this.edit_data.sex
                this.model.phone = this.edit_data.phone
                this.model.card_id = this.edit_data.card_id
                this.model.email = this.edit_data.email
                this.model.address = this.edit_data.address
                this.model.photo = this.edit_data.photo
                this.model.position_id = this.edit_data.position_id
                this.model.hire_date = this.edit_data.hire_date
                this.model.probation_end_date =
                    this.edit_data.probation_end_date
                this.model.status_id = this.edit_data.status_id
                this.model.status_effective_date =
                    this.edit_data.status_effective_date
                this.model.shift_id = this.edit_data.shift_id
                this.model.pc_date_id = this.edit_data.pc_date_id
                this.model.payment_type_id = this.edit_data.payment_type_id
                this.model.is_tax_responsed_by_company =
                    this.edit_data.is_tax_responsed_by_company
                this.model.is_spouse_dependant =
                    this.edit_data.is_spouse_dependant
                this.model.num_child_dependant =
                    this.edit_data.num_child_dependant
                this.model.nssf_number = this.edit_data.nssf_number
                this.model.ssn_no = this.edit_data.ssn_no
                this.model.ssn_issued_date = this.edit_data.ssn_issued_date
                this.model.ssn_expiry_date = this.edit_data.ssn_expiry_date
                this.model.ssn_issued_at = this.edit_data.ssn_issued_at
                this.model.nationality_id = this.edit_data.nationality_id
                this.model.date_of_birth = this.edit_data.date_of_birth
                this.model.emp_type_id = this.edit_data.emp_type_id
                this.model.fp_serial_card = this.edit_data.fp_serial_card
                this.model.status_reason_id = this.edit_data.status_reason_id
                    ? this.edit_data.status_reason_id
                    : null
                this.model.num_of_payroll = this.edit_data.num_of_payroll
                this.model.staff_type_id = this.edit_data.staff_type_id
            }
        },
        notice (not) {
            this.$Notice[not.type]({
                title: 'EMPLOYEE',
                desc: not.text
            })
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            vm.fetchResource()
        })
    },
    beforeRouteLeave (to, from, next) {
        AppOptions.appContentFullHeight = false
        AppOptions.appContentClass = ''
        next()
    }
}
</script>
